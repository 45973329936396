<template>
  <div class="keyword-search">
    <form
      novalidate
      class="keyword-search__form"
      @input="searchValue"
      @submit.prevent=""
    >
      <input
        type="text"
        id="keyword"
        ref="keywordSearch"
        :placeholder="placeholderText"
        v-model="keywordValue"
      />
    </form>
    <button
      v-if="keywordValue"
      class="keyword-search__form--button"
      @click="clearSearchValue"
    >
      X
    </button>
  </div>
</template>

<script>
import { Filters } from "../Shared/strings";
export default {
  data() {
    return {
      keywordValue: this.$store.state.searchKeyword,
      placeholderText: Filters.keywordPlaceholder,
    };
  },
  methods: {
    searchValue() {
      this.$store.dispatch("searchWaterPoints", this.keywordValue);
    },
    clearSearchValue() {
      this.$refs.keywordSearch.value = null;
      this.$store.dispatch("searchWaterPoints", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.keyword-search {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-left: 14px;
  position: relative;
  height: 100%;
  &__form {
    &--button {
      line-height: 1;
      padding: 0;
      height: 24px;
      width: 24px;
      flex: 1;
      border-radius: 24px;
      position: absolute;
      right: 8px;
    }
  }
}
</style>
