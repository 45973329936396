<template>
  <div class="container">
    <ProvidersFilter v-if="numProviders > 1" />
    <h2 v-else>
      {{ this.$store.getters.getSelectedOrg.name }}
    </h2>
    <KeywordSearch />
    <AdditionalFilter />
  </div>
</template>

<script>
import ProvidersFilter from "./ProvidersFilter.vue";
import AdditionalFilter from "./AdditionalFilter.vue";
import KeywordSearch from "./KeywordSearch.vue";

export default {
  components: { ProvidersFilter, AdditionalFilter, KeywordSearch },
  computed: {
    numProviders() {
      return this.$store.getters.getProviders.length;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  align-items: center;
}
</style>
