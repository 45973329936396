<template>
  <div class="filter">
    <div class="filter-header">
      <div v-if="!isMobile" class="filter-header-applied">
        <div
          class="filter-header-applied-card"
          v-for="(applied, index) in appliedFilters"
          :key="index"
        >
          <p class="filter-header-applied-card-text label-medium">
            {{ appliedKeyName(applied) }}: {{ appliedValue(applied) }}
          </p>
          <svg
            viewBox="0 96 960 960"
            @click="resetFilterApplied(applied)"
            class="filter-header-applied-card-circlex"
          >
            <path
              d="M331.913 768 480 619.913 628.087 768 672 724.087 523.913 576 672 427.913 628.087 384 480 532.087 331.913 384 288 427.913 436.087 576 288 724.087 331.913 768Zm148.12 213.978q-83.468 0-157.541-31.878-74.073-31.879-129.393-87.199-55.32-55.32-87.199-129.36-31.878-74.04-31.878-157.508 0-84.468 31.878-158.541 31.879-74.073 87.161-128.906 55.283-54.832 129.341-86.818 74.057-31.986 157.545-31.986 84.488 0 158.589 31.968 74.102 31.967 128.916 86.768 54.815 54.801 86.79 128.883Q886.218 491.484 886.218 576q0 83.501-31.986 157.57-31.986 74.069-86.818 129.36-54.833 55.291-128.873 87.17-74.04 31.878-158.508 31.878Zm-.033-68.13q141.043 0 239.446-98.752Q817.848 716.344 817.848 576q0-141.043-98.402-239.446-98.403-98.402-239.566-98.402-140.163 0-238.945 98.402-98.783 98.403-98.783 239.566 0 140.163 98.752 238.945Q339.656 913.848 480 913.848ZM480 576Z"
            />
          </svg>
        </div>
      </div>
      <button
        class="filter-title button button--transparent"
        @click="isOpen = !isOpen"
        :class="{ 'filter-title-open': isOpen }"
        v-if="this.appliedFilters.length === 1"
        v-show="false"
      >
        1
      </button>
      <button
        class="filter-title button button--transparent"
        @click="isOpen = !isOpen"
        :class="{ 'filter-title-open': isOpen }"
        v-else
      >
        + Filter
      </button>
    </div>
    <div class="filter-container" v-if="isOpen" v-click-outside="closeFilter">
      <div class="filter-container-header">
        <!-- <img
          class="filter-container-header-search-icon"
          alt="Search icon"
          src="../../assets/icon-search-gray.png"
        />
        <input
          v-model="searchResult"
          @change="handleSearchResults"
          placeholder="Search"
          class="filter-container-header-search-bar"
          type="text"
          id="searchBar"
        /> -->
      </div>
      <div class="filter-container-content">
        <div v-if="!labelSelected" class="filter-container-content-default">
          <p class="filter-container-content-default-label">FILTER BY</p>
          <button
            v-for="label in labels"
            :key="label.key"
            class="button button--grey"
            @click="setFilterSelected(label)"
          >
            {{ label.name }}
          </button>
        </div>
        <div v-else class="filter-container-content-selected">
          <div class="filter-container-content-selected-header">
            <img
              @click="labelSelected = ''"
              class="filter-container-content-selected-header-arrow"
              alt="arrow"
              src="../../assets/arrow.png"
            />
            <button
              class="filter-container-content-selected-header-card button button--grey"
            >
              {{ labelSelected.name }}
            </button>
          </div>
          <div class="filter-container-content-selected-results">
            <p
              class="filter-container-content-selected-results-options"
              v-for="option in options"
              :key="option"
              @click="applyFilter(option)"
            >
              {{ option }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
export default {
  data() {
    return {
      options: [],
      labels: [
        { name: "Status", key: "status" },
        { name: "Location Type", key: "locationType" },
        { name: "Community", key: "community" },
        { name: "Admin Unit 1", key: "administrativeUnit1" },
      ],
      labelSelected: "",
      filterSelected: "",
      isOpen: false,
      searchResult: "",
      appliedFilters: this.$store.getters.getAdditionalFilters,
      isMobile: false,
    };
  },
  computed: {
    filterObject() {
      const filter = {};
      filter[this.labelSelected.key] = this.filterSelected;
      return filter;
    },
  },
  methods: {
    closeFilter() {
      this.isOpen = false;
    },
    checkAppliedFilters(label) {
      if (
        this.appliedFilters.some((filter) =>
          Object.prototype.hasOwnProperty.call(filter, label.key),
        )
      ) {
        return true;
      }
      return false;
    },
    appliedKeyName(applied) {
      const appliedKey = Object.keys(applied)[0];
      const foundKey = this.labels.find((label) => label.key === appliedKey);
      return foundKey ? foundKey.name : "-";
    },
    appliedValue(applied) {
      return Object.values(applied)[0];
    },
    setFilterSelected(selectedLabel) {
      this.labelSelected = selectedLabel;

      // finds the currently applied label of the currently category
      const currentAppliedValues = this.appliedFilters
        .filter((a) => a[selectedLabel.key])
        .map((a) => a[selectedLabel.key]);

      if (selectedLabel) {
        let options = this.$store.state.waterPoints
          .map((elem) => elem[selectedLabel.key])
          .filter((item, index, self) => self.indexOf(item) === index)
          .filter((item) => !currentAppliedValues.includes(item));

        this.options = options;
      }
    },
    applyFilter(option) {
      if (this.appliedFilters.length >= 4) {
        return;
      }
      const existingFilter = this.appliedFilters.find((filter) => {
        return filter[this.labelSelected.key] === option;
      });
      if (existingFilter) {
        return;
      }
      this.filterSelected = option;
      this.appliedFilters.push(this.filterObject);
      this.$store.dispatch("filterWaterPoints", {
        additionalFilters: this.appliedFilters,
      });
      this.isOpen = !this.isOpen;
      this.labelSelected = "";
    },
    resetFilterApplied(option) {
      this.appliedFilters = this.appliedFilters.filter(
        (elem) => elem !== option,
      );
      this.$store.dispatch("filterWaterPoints", {
        additionalFilters: this.appliedFilters,
      });
    },
    handleSearchResults() {},
  },
  mounted() {
    this.isMobile = window.innerWidth <= 500;
    //Todo: find an elegant way to display the filters
  },
  directives: { clickOutside: vClickOutside.directive },
};
</script>

<style scoped lang="scss">
.filter {
  z-index: 1;
  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    &-applied {
      display: flex;
      flex-direction: row;
      &-card {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #f1f3f9;
        border-radius: 100px;
        padding: 0px 12px;
        &-text {
          color: var(--txt-primary);
        }
        &-circlex {
          width: 14px;
          height: 14px;
          margin-left: 8px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  &-container {
    position: absolute;
    max-height: 300px;
    background-color: #ffffff;
    border: 1px solid #f1f3f9;
    overflow-y: auto;
    z-index: 2;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    &-header {
      border-bottom: 1px solid #e1e2e6;
      &-search {
        &-bar {
          width: 13em;
          height: 3.5em;
          padding-left: 2.8em;
          font-size: 0.75em;
          border: none;
        }
        &-icon {
          position: absolute;
          width: 0.8em;
          top: 1em;
          left: 0.9em;
        }
      }
    }
    &-content {
      &-default,
      &-selected-header {
        &-card {
          margin: 0 16px;
        }
      }
    }
    &-content {
      button {
        font-size: 12px;
        padding: 8px;
      }
      &-default {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
        padding: 16px;
        &-label {
          color: #7a7e87;
          color: var(--txt-icon-disabled);
          font-weight: 700;
          margin: 0;
        }
        &-card {
          &:hover {
            cursor: pointer;
          }
        }
      }
      &-selected {
        &-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-left: var(--base-font-size);
          padding-top: 0.5em;
          &-arrow {
            width: 1em;
            rotate: (-90deg);
            &:hover {
              cursor: pointer;
            }
          }
        }
        &-results {
          overflow: auto;
          &-options {
            font-size: 14px;
            line-height: 20px;
            padding-left: 1em;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
