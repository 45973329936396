<template>
  <div class="historical-status">
    <div class="filters">
      <div class="filters-containerTt">
        <p
          class="filters-containerTt-title paragraph-medium paragraph-medium--heavy"
        >
          Historical Status
        </p>
        <p class="filters-containerTt-subtitle paragraph-xs">All sensors</p>
      </div>
      <div class="filter-selects">
        <SelectMenu
          v-if="canChangeChartDate(roleLoggedUser)"
          :options="timeframeOptions"
          :selected="selectedTimeFrame"
          @menuItemChanged="onTimeFrameChange"
        />
        <SelectMenu
          :options="statusOptionsNoGrey"
          :selected="selectedStatus"
          @menuItemChanged="onStatusChange"
        />
      </div>
    </div>
    <div class="chart-container">
      <canvas id="historical-status"></canvas>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js";
import { mapState } from "vuex";
import {
  historicalStatusChartConfig,
  HISTORICAL_STATUS_BLUE_DARK,
  HISTORICAL_STATUS_BLUE_LIGHT,
  HISTORICAL_STATUS_GREEN_DARK,
  HISTORICAL_STATUS_GREEN_LIGHT,
  HISTORICAL_STATUS_GREY_DARK,
  HISTORICAL_STATUS_GREY_LIGHT,
  HISTORICAL_STATUS_RED_DARK,
  HISTORICAL_STATUS_RED_LIGHT,
  HISTORICAL_STATUS_YELLOW_DARK,
  HISTORICAL_STATUS_YELLOW_LIGHT,
} from "../../chart/chartConfig";
import moment from "moment";
import SelectMenu from "../Shared/SelectMenu.vue";

export default {
  data() {
    const timeframeOptions = {
      days_7: {
        value: "days_7",
        label: "Last 7 Days",
      },
      days_30: {
        value: "days_30",
        label: "Last 30 Days",
      },
      months_3: {
        value: "months_3",
        label: "Last 3 Months",
      },
      months_6: {
        value: "months_6",
        label: "Last 6 Months",
      },
      months_12: {
        value: "months_12",
        label: "Last 12 Months",
      },
    };
    const statusOptions = {
      all_sensors: {
        value: "all_sensors",
        label: "All Sensors",
      },
      red: {
        value: "red",
        label: "Red",
      },
      yellow: {
        value: "yellow",
        label: "Yellow",
      },
      green: {
        value: "green",
        label: "Green",
      },
      non_active: {
        value: "non_active",
        label: "Non-Active",
      },
    };
    return {
      chartData: {
        labels: [],
        status: [],
      },
      timeframeOptions,
      statusOptions,
      selectedStatus: statusOptions.red,
      selectedTimeFrame: timeframeOptions.days_30,
      chart: null,
    };
  },
  components: { SelectMenu },
  computed: {
    ...mapState({
      historicalData: (state) => state.waterPointsHistoricalFlag,
    }),
    // TODO: use all options after backend fix grey status data
    statusOptionsNoGrey() {
      // eslint-disable-next-line
      const { non_active, ...otherOptions } = this.statusOptions;
      return otherOptions;
    },
    roleLoggedUser() {
      return this.$store.getters.roleLoggedUser;
    },
    selectedStartTimeFromNow() {
      let ret = moment();
      if (this.selectedTimeFrame.value === this.timeframeOptions.days_7.value) {
        ret.subtract(7, "day");
      } else if (
        this.selectedTimeFrame.value === this.timeframeOptions.days_30.value
      ) {
        ret.subtract(30, "day");
      } else if (
        this.selectedTimeFrame.value === this.timeframeOptions.months_3.value
      ) {
        ret.subtract(3, "month");
      } else if (
        this.selectedTimeFrame.value === this.timeframeOptions.months_6.value
      ) {
        ret.subtract(6, "month");
      } else if (
        this.selectedTimeFrame.value === this.timeframeOptions.months_12.value
      ) {
        ret.subtract(12, "month");
      }
      return ret;
    },
  },
  methods: {
    onTimeFrameChange(selectedItem) {
      this.selectedTimeFrame = selectedItem;
      this.updateChart();
    },
    onStatusChange(data) {
      this.selectedStatus = data;
      this.updateChart();
    },
    canChangeChartDate(role) {
      return role === "SuperAdmin" || role === "Admin" || role === "ViewEdit";
    },
    updateChart() {
      const dataFilteredByDate = this.historicalData.filter((point) => {
        return moment(point.dateDay) >= this.selectedStartTimeFromNow;
      });
      const labels = dataFilteredByDate.map((item) =>
        moment(item.dateDay).format("D MMM YYYY").toUpperCase(),
      );
      const data = dataFilteredByDate.map((item) => {
        let ret = undefined;
        switch (this.selectedStatus.value) {
          case this.statusOptions.all_sensors.value:
            // TODO: add grey sensor counts after backend fix
            ret = item.green + item.yellow + item.red;
            break;
          case this.statusOptions.red.value:
          case this.statusOptions.yellow.value:
          case this.statusOptions.green.value:
            ret = item[this.selectedStatus.value];
            break;
          case this.statusOptions.non_active.value:
            ret = item["grey"];
            break;
          default:
            ret = undefined;
        }
        return ret;
      });

      // auto limit ticks if less than 2 months of data is showing
      if (labels.length >= 60) {
        this.chart.options.scales.xAxes[0].ticks.maxTicksLimit = labels.length;
      } else {
        this.chart.options.scales.xAxes[0].ticks.maxTicksLimit = 6;
      }
      this.chart.data.labels = labels;
      this.chart.data.datasets[0].data = data;
      this.chart.data.datasets[0].label = this.selectedStatus.label;
      this.updateChartColor(this.selectedStatus);
      this.chart.update();
    },
    updateChartColor(status) {
      let borderColor = "";
      let backgroundColor = "";
      switch (status.value) {
        case this.statusOptions.all_sensors.value:
          borderColor = HISTORICAL_STATUS_BLUE_DARK;
          backgroundColor = HISTORICAL_STATUS_BLUE_LIGHT;
          break;
        case this.statusOptions.red.value:
          borderColor = HISTORICAL_STATUS_RED_DARK;
          backgroundColor = HISTORICAL_STATUS_RED_LIGHT;
          break;
        case this.statusOptions.yellow.value:
          borderColor = HISTORICAL_STATUS_YELLOW_DARK;
          backgroundColor = HISTORICAL_STATUS_YELLOW_LIGHT;
          break;
        case this.statusOptions.green.value:
          borderColor = HISTORICAL_STATUS_GREEN_DARK;
          backgroundColor = HISTORICAL_STATUS_GREEN_LIGHT;
          break;
        case this.statusOptions.non_active.value:
          borderColor = HISTORICAL_STATUS_GREY_DARK;
          backgroundColor = HISTORICAL_STATUS_GREY_LIGHT;
          break;
        default:
          break;
      }
      this.chart.data.datasets[0].borderColor = borderColor;
      this.chart.data.datasets[0].backgroundColor = backgroundColor;
    },
    createChart() {
      const config = historicalStatusChartConfig;
      this.chart = new Chart(
        document.getElementById("historical-status").getContext("2d"),
        config,
      );
      this.updateChart();
    },
  },
  watch: {
    historicalData() {
      if (this.chart) {
        this.updateChart();
      }
    },
  },
  mounted() {
    // only make API call if no data is present
    if (this.historicalData.length <= 0) {
      this.$store.dispatch("fetchHistoricalFlagStatus").then(() => {
        this.createChart();
      });
    } else {
      this.createChart();
    }
  },
};
</script>

<style scoped lang="scss">
.historical-status {
  border: 2px solid #f1f3f9;
  border-radius: 0.5em;

  @media screen and (min-width: 2560px) {
    width: 40em;
  }
  @media screen and (max-width: 1440px) {
    width: 30em;
  }
  @media screen and (max-width: 1024px) {
    width: 22em;
  }
  @media screen and (max-width: 767px) {
    width: 22em;
  }

  .filter-selects {
    display: flex;
    gap: 8px;
  }

  .filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
    gap: 1em;
    &-containerTt {
      font-size: var(--base-font-size);
      &-title {
        margin-bottom: 0px;
        font-weight: 700;
      }
      &-subtitle {
        margin-top: 0;
        color: var(--txt-icon-disabled);
      }
    }
    select {
      padding: 8px 10px;
      padding-right: 20px;
      font-size: 14px;
      border: 1px solid #e1e6ef;
      border-radius: 8px;
      background-color: white;
      font-family: var(--app-font);
      &:hover {
        cursor: pointer;
      }
    }
  }
  .chart-container {
    display: block;
    margin-left: 16px;
    @media screen and (max-width: 767px) {
      max-width: 25em;
      height: 25em;
    }
    canvas {
      position: relative;
    }
  }
}
.selectLeft {
  margin-right: 0.5em;
}
</style>
