<template>
  <div>
    <md-menu
      :md-size="'medium'"
      :md-align-trigger="true"
      class="filter-component"
      ref="menuRef"
      :md-close-on-select="false"
      @click="onClick"
    >
      <md-button
        md-menu-trigger
        class="filter-component-filter"
        :value="labelWithQuantity"
      >
        {{ allServiceProvidersLabelValue }}
      </md-button>
      <md-menu-content>
        <md-menu-item @click="resetFilterWaterPoints"
          >All Service Providers</md-menu-item
        >
        <md-menu-item
          v-for="(item, index) in options"
          @click="filterWaterPoints(item, $event)"
          :value="item"
          :key="index"
          :class="{ 'filter-component-option-selected': isSelected(item) }"
        >
          {{ item ? shortenServiceProviders(item) : "Unassigned" }}
          <Checkmark class="selectedCheckmark" v-if="isSelected(item)" />
          <span v-else>
            <input @change="filterWaterPoints(item)" type="checkbox"
          /></span>
        </md-menu-item>
      </md-menu-content>
    </md-menu>
  </div>
</template>

<script>
import Checkmark from "../Shared/Checkmark.vue";
export default {
  components: { Checkmark },
  data() {
    return {
      selectedOption: "all",
      labelWithQuantity: "labelWithQuantity",
      multipleSelectedOptions: this.$store.state.filterProviders,
      allServiceProvidersValue: "all",
      allServiceProvidersLabelValue: "All Service Providers",
    };
  },
  methods: {
    onClick() {
      // dummy function to allow menu not close on select
      // https://www.creative-tim.com/vuematerial/components/menu#:~:text=This%20will%20only%20work%20if%20the%20menu%20have%20one%20of%20the%20events%20below%3A
      return 1;
    },
    shortenServiceProviders(serviceProvider) {
      if (serviceProvider.length >= 25) {
        return serviceProvider.substr(0, 19);
      }
      return serviceProvider;
    },
    isSelected(item) {
      return this.multipleSelectedOptions.includes(item);
    },
    updateLabel() {
      if (this.multipleSelectedOptions.length >= 2) {
        this.allServiceProvidersLabelValue = `Service Providers (${this.multipleSelectedOptions.length})`;
      } else {
        this.allServiceProvidersLabelValue = `${
          this.multipleSelectedOptions[0] ?? "Unassigned"
        }`;
      }
      if (this.multipleSelectedOptions.length === 0) {
        this.allServiceProvidersLabelValue = `All Service Providers`;
      }
    },
    filterWaterPoints(val, event) {
      // If toggling the checkmark, don't close the menu
      if (
        event.target.type == "checkbox" ||
        event.target.classList.contains("selectedCheckmark")
      ) {
        event.stopPropagation();
      }

      this.selectedOption = val;
      if (this.selectedOption === "all") {
        this.multipleSelectedOptions = [];
      } else {
        const index = this.multipleSelectedOptions.indexOf(this.selectedOption);
        if (index !== -1) {
          this.multipleSelectedOptions.splice(index, 1);
        } else {
          this.multipleSelectedOptions.push(this.selectedOption);
        }
      }
      this.updateLabel();

      // filter waterpoint based on selection
      this.multipleSelectedOptions.length
        ? this.$store.dispatch("filterWaterPoints", {
            filterProviders: this.multipleSelectedOptions,
          })
        : this.$store.dispatch("filterWaterPoints", {
            filterProviders: "all",
          });
      this.$store.dispatch("fetchHistoricalFlagStatus");

      if (this.selectedOption !== "all") {
        this.selectedOption = "labelWithQuantity";
      }
    },
    resetFilterWaterPoints() {
      this.multipleSelectedOptions = [];
      this.updateLabel();
      this.$store.dispatch("filterWaterPoints", {
        filterProviders: "all",
      });
      this.$store.dispatch("fetchHistoricalFlagStatus");
    },
  },
  computed: {
    options() {
      return this.$store.state.providers;
    },
  },
  mounted() {
    this.updateLabel();
  },
};
</script>

<style scoped lang="scss">
.filter-component {
  display: flex;
  align-items: center;

  .md-button {
    text-transform: none;
    padding: 12px 24px 12px 8px;
  }
  .md-ripple {
    padding: 0;
  }

  &-filter {
    display: block;
    width: 100%;
    border: 1px solid #333;
    font-family: inherit;
    appearance: none;
    padding-right: 1.5rem;
    padding-left: 0.1rem;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 1em;
    background-color: white;
    height: auto;
    border-radius: 0.5em;
    border: 2px solid #003366;
    margin-right: 1em;
    font-size: var(--base-font-size);
    font-weight: 700;
    color: #222520;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 767px) {
      padding-left: 0.5rem;
      width: 90%;
    }
  }

  &-title {
    font-size: var(--base-font-size);
    font-weight: 700;
    color: #222520;
    &:hover {
      cursor: pointer;
    }
  }

  &-option-selected {
    background-color: #f1f3f9;
  }

  select option:disabled {
    display: none;
  }
}
.md-menu-item {
  width: 13rem;
  height: 2.75em;
}
</style>
