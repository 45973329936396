<template>
  <div class="status-sensor">
    <div class="status-sensor-info">
      <p
        class="status-sensor-info-title paragraph-medium paragraph-medium--heavy"
      >
        Status
      </p>
      <div class="status-sensor-info-resume">
        <h3
          class="status-sensor-info-resume-num heading-medium heading-medium--heavy"
        >
          {{ quantityActiveSensors }}
        </h3>
        <p
          class="status-sensor-info-resume-text paragraph-medium paragraph-medium--heavy"
        >
          Active Sensors
        </p>
      </div>
    </div>
    <div class="chartsensors-wrapper">
      <ChartSensors />
    </div>
  </div>
</template>

<script>
import ChartSensors from "@/components/Dashboard/ChartSensors.vue";
export default {
  components: {
    ChartSensors,
  },
  computed: {
    quantityActiveSensors() {
      const activeSensors = this.$store.state.filteredWaterPoints.filter(
        (elem) => elem.status != "Grey",
      );
      return activeSensors.length;
    },
  },
};
</script>

<style scoped lang="scss">
.status-sensor {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 24px;

  justify-content: space-between;
  align-items: center;
  border: 2px solid #f1f3f9;
  border-radius: 0.5em;
  @media screen and (max-width: 1024px) {
    gap: 10px;
    width: auto;
  }
  @media screen and (max-width: 767px) {
    justify-content: center;
    gap: 10px;
    width: auto;
  }
  &-info {
    margin-left: 1em;
    &-title {
      color: var(--clr-txt-gray);
      font-size: var(--base-font-size);
      margin-top: 0;
    }
    &-resume {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      &-num {
        color: #222520;
        margin: 0;
      }
      &-text {
        color: var(--txt-icon-disabled);
        margin: 0;
      }
    }
  }
  .chartsensors-wrapper {
    flex: 1;
  }
}
</style>
