<template>
  <div class="waterPoints">
    <table class="table" cellspacing="0">
      <tr class="table-header">
        <th class="waterPoints-table-header-waterPoint">
          WaterPoint
          <img
            class="arrow-up-down"
            alt="arrow-up-down"
            src="../../assets/arrow-up-down.png"
            @click="orderByWaterPoint"
          />
        </th>
        <th class="waterPoints-table-header-id">ID</th>
        <th class="waterPoints-table-header-status">
          Status
          <img
            class="arrow-up-down"
            alt="arrow-up-down"
            src="../../assets/arrow-up-down.png"
            @click="orderByColorsPoint"
          />
        </th>
        <th class="waterPoints-table-header-title">
          <div class="wrapper" style="display: flex; flex-direction: row">
            % Change
            <InfoToolTip
              :position="'bottom'"
              :text="percentChangeToolTipText"
            />
          </div>
        </th>
        <th class="waterPoints-table-header-servProv">
          0 Liter Days
          <InfoToolTip :position="'bottom'" :text="zeroLiterDaysToolTipText" />
        </th>
        <th v-if="numProviders > 1" class="waterPoints-table-header-servProv">
          Service Provider
        </th>
        <th class="waterPoints-table-header-title">
          <div
            class="wrapper"
            style="display: flex; flex-direction: row; align-items: center"
          >
            Last Updated
            <InfoToolTip :position="'bottom'" :text="lastUpdatedToolTipText" />
          </div>
        </th>
        <th class="waterPoints-table-header-dots"></th>
      </tr>
      <tr
        v-for="(point, index) in orderedArray"
        :key="index"
        class="waterPoints-table-content"
      >
        <!-- @click="redirectToDetail(point.name.toLowerCase().replace(/\s/g, '-'))" -->
        <td
          class="waterPoints-table-content-mainTitle"
          @click="redirectToDetail(point)"
        >
          <div class="waterPoints-table-content--wrapper">
            <p>
              {{ point.waterPoint ? shortenWaterpoint(point.waterPoint) : "-" }}
            </p>
            <template v-if="point.locationType === 'School'">
              <tool-tip title="School" position="top">
                <svg
                  color="currentColor"
                  viewBox="0 96 960 960"
                  class="school-logo"
                >
                  <path
                    d="M479 941.652 183.348 780.391v-240L27.565 456 479 209.782 932.435 456v323.218h-73v-280.87l-84.217 42.043v240L479 941.652Zm0-322.131L778.173 456 479 295.479 181.827 456 479 619.521Zm0 239.435 222.652-123.043V583.74L479 702.218 256.348 581.74v154.173L479 858.956Zm1-239.435Zm-1 79.653Zm0 0Z"
                  />
                </svg>
              </tool-tip>
            </template>
          </div>
        </td>
        <td class="point-id">{{ point.sensorId }}</td>
        <td
          class="waterPoints-table-content-title"
          @click="redirectToDetail(point)"
        >
          <ToolTip class="tool-tip" :title="statusToolTipTitle(point.status)">
            <StatusPill :status="point.status"></StatusPill>
          </ToolTip>
        </td>

        <td
          class="waterPoints-table-content-title"
          @click="redirectToDetail(point)"
        >
          {{ point.percentChange ? getPercent(point.percentChange) : "-" }}
        </td>
        <td class="waterPoints-table-content-title">
          {{ point.zeroDays ? point.zeroDays : "0" }} days
        </td>
        <td
          v-if="numProviders > 1"
          class="waterPoints-table-content-title"
          @click="redirectToDetail(point)"
        >
          {{ point.serviceProvider ? point.serviceProvider : "-" }}
        </td>
        <td
          class="waterPoints-table-content-title"
          @click="redirectToDetail(point)"
        >
          {{ getLastRecorded(point.lastRecorded) }}
        </td>
        <td class="waterPoints-table-content-dots kebabs">
          <Kebab
            :options="kebabOptions"
            :payload="point"
            @menuItemChanged="handlePanel"
          />
        </td>
      </tr>
    </table>
    <Drawer :show.sync="showEditSensor">
      <template>
        <SensorForm
          :point="selectedSensor"
          @close="showEditSensor = false"
          :isEdit="true"
        />
      </template>
    </Drawer>
    <ExportWaterFlow
      v-if="showExportWaterFlow"
      @close="closeModal"
      :sensorId="selectedSensor.id"
    />
  </div>
</template>

<script>
import StatusPill from "../Shared/StatusPill.vue";
import ToolTip from "../Shared/ToolTip.vue";
import Kebab from "../Shared/Kebab.vue";
import Drawer from "../Shared/Drawer.vue";
import SensorForm from "../Forms/SensorForm.vue";
import ExportWaterFlow from "../Details/ExportWaterFlow.vue";
import InfoToolTip from "../Shared/InfoToolTip.vue";
import { TooltipsText } from "../Shared/strings";
import { KebabOptions } from "../Shared/strings";

export default {
  data() {
    return {
      selectedOption: "default",
      showEditSensor: false,
      colorsOrderArr: ["Red", "Yellow", "Green", "Grey"],
      tableOrderEnum: {
        descWaterPoint: "descWaterPoint",
        ascWaterPoint: "ascWaterPoint",
        descColors: "descColors",
        ascColors: "ascColors",
        default: "default",
      },
      currentTableState: "default",
      showExportWaterFlow: false,
      selectedSensor: null,
      percentChangeToolTipText: TooltipsText.waterPoint.percentChange,
      lastUpdatedToolTipText: TooltipsText.waterPoint.lastUpdated,
      zeroLiterDaysToolTipText: TooltipsText.waterPoint.zeroLiterDays,
    };
  },
  computed: {
    waterPoints() {
      return this.$store.state.filteredWaterPoints;
    },
    roleLoggedUser() {
      return this.$store.getters.roleLoggedUser;
    },
    numProviders() {
      return this.$store.getters.getProviders.length;
    },
    kebabOptions() {
      const options = { ...KebabOptions };
      const roleLoggedUser = this.$store.getters.roleLoggedUser;
      if (
        roleLoggedUser !== "SuperAdmin" &&
        roleLoggedUser !== "Admin" &&
        roleLoggedUser !== "ViewEdit"
      ) {
        let reducedOptions = options;
        delete reducedOptions.editSensor;
        delete reducedOptions.export;
        return reducedOptions;
      } else {
        return options;
      }
    },
    orderedArray() {
      let resultArr = [];

      switch (this.currentTableState) {
        case this.tableOrderEnum.descWaterPoint:
          resultArr = [...this.waterPoints].slice().sort((a, b) => {
            // If waterPoint is empty, place it at the end of the list
            const _a = a.waterPoint ? a.waterPoint.toLowerCase() : "zzz";
            const _b = b.waterPoint ? b.waterPoint.toLowerCase() : "zzz";
            return _a < _b ? 1 : -1;
          });
          break;

        case this.tableOrderEnum.ascWaterPoint:
          resultArr = [...this.waterPoints].slice().sort((a, b) => {
            // If waterPoint is empty, place it at the end of the list
            const _a = a.waterPoint ? a.waterPoint.toLowerCase() : "zzz";
            const _b = b.waterPoint ? b.waterPoint.toLowerCase() : "zzz";
            return _a > _b ? 1 : -1;
          });
          break;

        case this.tableOrderEnum.ascColors:
          resultArr = [...this.waterPoints].sort((a, b) => {
            const _a = a.status;
            const _b = b.status;
            if (
              this.colorsOrderArr.indexOf(_a) < this.colorsOrderArr.indexOf(_b)
            ) {
              return 1;
            }
            if (
              this.colorsOrderArr.indexOf(_a) > this.colorsOrderArr.indexOf(_b)
            ) {
              return -1;
            }
            return 0;
          });
          break;

        case this.tableOrderEnum.descColors:
          resultArr = [...this.waterPoints].sort((a, b) => {
            const _a = a.status;
            const _b = b.status;
            if (
              this.colorsOrderArr.indexOf(_a) < this.colorsOrderArr.indexOf(_b)
            ) {
              return -1;
            }
            if (
              this.colorsOrderArr.indexOf(_a) > this.colorsOrderArr.indexOf(_b)
            ) {
              return 1;
            }
            return 0;
          });
          break;

        default:
          resultArr = this.waterPoints;
          break;
      }
      return resultArr;
    },
  },
  methods: {
    statusToolTipTitle(status) {
      let label = "";
      switch (true) {
        case status === "Red":
          label = "Attention Needed";
          break;
        case status === "Yellow":
          label = "Abnormal Behavior - Check with Community";
          break;
        case status === "Green":
          label = "Normal Operation";
          break;
        case status === "Grey":
          label =
            "No communication for last month, or has not yet been installed";
          break;
        default:
          break;
      }
      return label;
    },
    closeModal() {
      this.showExportWaterFlow = false;
    },
    shortenWaterpoint(waterpoint) {
      if (waterpoint.length >= 40) {
        return waterpoint.substr(0, 40) + " ...";
      }
      return waterpoint;
    },
    orderByWaterPoint() {
      if (
        this.currentTableState === this.tableOrderEnum.default ||
        this.currentTableState === this.tableOrderEnum.descWaterPoint ||
        this.currentTableState === this.tableOrderEnum.descColors ||
        this.currentTableState === this.tableOrderEnum.ascColors
      ) {
        this.currentTableState = this.tableOrderEnum.ascWaterPoint;
      } else if (this.currentTableState === this.tableOrderEnum.ascWaterPoint) {
        this.currentTableState = this.tableOrderEnum.descWaterPoint;
      }
    },
    orderByColorsPoint() {
      if (
        this.currentTableState === this.tableOrderEnum.default ||
        this.currentTableState === this.tableOrderEnum.descColors
      ) {
        this.currentTableState = this.tableOrderEnum.ascColors;
      } else if (
        this.currentTableState === this.tableOrderEnum.ascColors ||
        this.currentTableState === this.tableOrderEnum.descWaterPoint ||
        this.currentTableState === this.tableOrderEnum.ascWaterPoint
      ) {
        this.currentTableState = this.tableOrderEnum.descColors;
      }
    },
    getPercent(percent) {
      return `${Math.round(percent * 1000) / 1000}%`;
    },
    getLastRecorded(dateStr) {
      if (!dateStr || dateStr === "None") return "";
      const dt = new Date(dateStr);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      const datePart = dt.toLocaleString("en-UK", options).toUpperCase();

      const shortDateStr = datePart.replace(",", "");
      return shortDateStr;
    },
    redirectToDetail(point) {
      this.$store.dispatch("setDetailWaterPoint", point);
      this.$router.push({
        path: `/dashboard/${point.id}`,
      });
    },
    handlePanel(selected, point) {
      switch (selected.value) {
        case this.kebabOptions.editSensor.value:
          this.selectedSensor = point;
          this.showEditSensor = true;
          break;
        case this.kebabOptions.viewDetails.value:
          this.redirectToDetail(point);
          break;
        case this.kebabOptions.export.value:
          this.selectedSensor = point;
          this.showExportWaterFlow = true;
          break;
        default:
          break;
      }
      // reset the kebab
      this.selectedOption = "default";
    },
  },
  components: {
    InfoToolTip,
    ToolTip,
    StatusPill,
    Kebab,
    Drawer,
    SensorForm,
    ExportWaterFlow,
  },
};
</script>

<style scoped lang="scss">
.waterPoints {
  overflow-x: auto;
  max-height: 65vh;
  @media screen and (max-width: 767px) {
    max-height: 25em;
  }
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f1f3f9;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c1c5cd;
    border-radius: 5px;
    &:hover {
      background-color: #a6aab3;
    }
  }
  @media screen and (max-width: 767px) {
    overflow-x: none;
  }
  &-table {
    width: 100%;
    border: 2px solid #f1f3f9;
    border-radius: 0.5em;
    overflow-y: hidden;
    border-collapse: collapse;
    &-header {
      height: 2.5em;
      background-color: #f1f3f9;
      color: var(--txt-icon-primary);
      &-title,
      &-waterPoint,
      &-status,
      &-servProv {
        font-size: var(--base-font-size);
        .arrow-up-down {
          cursor: pointer;
          vertical-align: middle;
          width: 1.5em;
          padding-left: 3px;
          padding-bottom: 3px;
        }
      }
      &-waterPoint {
        width: 20em;
      }
      &-status {
        width: 7em;
      }
      &-servProv {
        width: 9em;
      }
      &-id {
        width: 7em;
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
    }

    &-content {
      &:hover {
        background-color: #f8f9fc;
      }
      &-mainTitle {
        padding: 1em 1em 1em;
        font-size: var(--base-font-size);
        display: table-cell;
        .waterPoints-table-content--wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          .tooltip {
            display: flex;
            align-items: center;
          }
        }
        .school-logo {
          height: 16px;
          width: 16px;
          color: #1d2433a6;
          margin-left: 6px;
        }
      }
      &-mainTitle:hover {
        cursor: pointer;
      }
    }
  }
}

.waterPoints-table-content-title {
  font-size: var(--base-font-size);
  width: 10em;
  cursor: pointer;
}

tr {
  border-bottom: 2px solid #f1f3f9;
}
td,
th {
  text-align: left;
}
td {
  p {
    margin: 0;
  }
  &.point-id {
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
}

th {
  &:first-child {
    padding-left: 1em;
  }
  &:last-child {
    padding-right: 1em;
  }
}
</style>
