<template>
  <div class="dashboard-page row">
    <NavBar />
    <div class="container-under-navbar">
      <div class="dashboard-page-header">
        <Filters />
        <HelpAction />
      </div>
      <div class="dashboard-page-content box">
        <div class="dashboard-page-content-status col-xs-8">
          <StatusSensor />
          <WaterPoints />
        </div>
        <div class="dashboard-page-content-historicalMap col-xs-4">
          <HistoricalStatus />
          <MapDashboard />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Filters from "@/components/NavComponents/Filters.vue";
import HelpAction from "../components/NavComponents/HelpAction.vue";
import HistoricalStatus from "@/components/Dashboard/HistoricalStatus.vue";
import StatusSensor from "@/components/Dashboard/StatusSensor.vue";
import WaterPoints from "@/components/Dashboard/WaterPoints.vue";
import MapDashboard from "@/components/Dashboard/MapDashboard.vue";
import NavBar from "../components/NavComponents/NavBar.vue";

export default {
  name: "default-component",
  props: {},
  model: {
    event: "updateModel",
  },
  components: {
    NavBar,
    Filters,
    HelpAction,
    HistoricalStatus,
    StatusSensor,
    WaterPoints,
    MapDashboard,
  },
  mounted() {
    this.$store.dispatch("getLoggedUserData");
    // request for waterpoint data here so that filter will have all providers.
    this.$store.dispatch("fetchWaterPoints").then(() => {
      // filter water points depends on a correct set of providers
      this.$store.dispatch("fetchProviders");
      this.$store.dispatch("filterWaterPoints", {
        filterProviders: this.$store.state.filterProviders,
      });
    });
  },
};
</script>

<style scoped lang="scss">
.dashboard-page {
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1.75em;
  }
  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    margin: 24px;
    &-status {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 1.25em;
    }
    &-historicalMap {
      display: flex;
      width: 25vw;
      flex-direction: column;
      gap: 1.25em;
      @media screen and (max-width: 767px) {
        align-items: center;
      }
    }
    @media screen and (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
