<template>
  <div class="mapDashboard">
    <gmap-map
      :center="center"
      :options="options"
      :zoom="zoomLevel"
      class="mapDashboard-gmap"
    >
      <gmap-marker
        v-for="(marker, index) in markers"
        :key="index"
        :position="marker.position"
        :icon="marker.icon"
        @click="toggleShowInfoWindow(marker)"
      />
      <gmap-info-window
        v-for="(marker, index) in markers"
        :key="'infowindow-' + index"
        :position="marker.position"
        :opened="marker.showInfoWindow === true"
        @closeclick="marker.showInfoWindow = false"
      >
        <div class="mapDashboard-info">
          <h3 class="mapDashboard-info-title">{{ marker.name }}</h3>
          <div class="mapDashboard-info-details">
            <p class="mapDashboard-info-details-id">
              Sensor ID {{ marker.sensorId }}
            </p>
            <LocationPill :location-type="marker.type"></LocationPill>
          </div>
          <div class="mapDashboard-info-container">
            <div class="mapDashboard-info-container-left">
              <div class="mapDashboard-info-container-left-latitude">
                <p class="mapDashboard-info-container-left-latitude-title">
                  Latitude
                </p>
                <p class="mapDashboard-info-container-left-latitude-value">
                  {{ formatCoordinate(marker.position.lat) }}
                </p>
              </div>
              <StatusPill :status="marker.status"></StatusPill>
            </div>

            <div class="mapDashboard-info-container-right">
              <div class="mapDashboard-info-container-right-longitude">
                <p class="mapDashboard-info-container-right-longitude-title">
                  Longitude
                </p>
                <p class="mapDashboard-info-container-right-longitude-value">
                  {{ formatCoordinate(marker.position.lng) }}
                </p>
              </div>
              <div class="mapDashboard-info-container-left-status">
                <span
                  @click="redirectToDetail(marker.id)"
                  class="mapDashboard-info-container-left-status-detail"
                >
                  View Details
                </span>
              </div>
            </div>
          </div>
        </div>
      </gmap-info-window>
    </gmap-map>
  </div>
</template>

<script>
import StatusPill from "../Shared/StatusPill.vue";
import LocationPill from "../Shared/LocationPill.vue";
import { mapState } from "vuex";
export default {
  components: { StatusPill, LocationPill },
  data() {
    return {
      options: {
        streetViewControl: false,
        scrollwheel: false,
        fullscreenControl: false,
        mapTypeControl: false,
      },
    };
  },
  computed: {
    ...mapState({
      filterProviders: (state) => state.filterProviders,
      additionalFilters: (state) => state.additionalFilters,
    }),
    waterPointsOnMap() {
      return this.$store.state.filteredWaterPoints.filter((waterPoint) => {
        return !this.isInvalidLatLng(waterPoint.latitude, waterPoint.longitude);
      });
    },
    zoomLevel() {
      if (this.markers.length > 0) {
        if (
          this.filterProviders.length > 0 ||
          this.additionalFilters.length > 0
        ) {
          // show closer zoom if filter applied
          return 11;
        } else {
          return 7;
        }
      } else {
        // show world map if no marker
        return 2;
      }
    },
    markers() {
      return this.waterPointsOnMap.map((elem) => {
        const position = {
          lat: Number(elem.latitude),
          lng: Number(elem.longitude),
        };
        const color = elem.status;
        return {
          position,
          icon: {
            path: "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0",
            fillColor:
              color === "Not Active" ? "grey" : `${color.toLowerCase()}`,
            fillOpacity: 1,
            strokeWeight: 0.1,
            scale: 0.6,
          },
          showInfoWindow: elem.showInfoWindow,
          name: elem.waterPoint,
          id: elem.id,
          sensorId: elem.sensorId,
          type: elem.locationType,
          status: elem.status,
        };
      });
    },
    center() {
      const locations = this.waterPointsOnMap.map((elem) => ({
        latitude: Number(elem.latitude),
        longitude: Number(elem.longitude),
        color: elem.status,
      }));
      if (locations.length === 0) {
        return { lat: 0, lng: 0 };
      }
      return { lat: locations[0].latitude, lng: locations[0].longitude };
    },
  },
  methods: {
    toggleShowInfoWindow(marker) {
      this.$store.dispatch("toggleShowInfoWindow", marker);
    },
    redirectToDetail(id) {
      this.$router.push({
        path: `/dashboard/${id}`,
      });
    },
    formatCoordinate(value) {
      return value.toFixed(3);
    },
    isInvalidLatLng(lat, lng) {
      if (lat === null || lng === null) {
        return true;
      }
      lat = Number(lat);
      lng = Number(lng);
      if (isNaN(lat) || isNaN(lng)) {
        // lat and lng needs to be a number
        return true;
      }
      // (0,0) is a default, so don't show (for now)
      return lat == 0 && lng == 0;
    },
  },
};
</script>

<style scoped lang="scss">
.mapDashboard {
  border-radius: 0.5em;
  &-gmap {
    height: 48vh;
    @media screen and (min-width: 2560px) {
      width: 40em;
    }
    @media screen and (max-width: 1440px) {
      width: 30em;
    }
    @media screen and (max-width: 1024px) {
      width: 22em;
    }
    @media screen and (max-width: 767px) {
      width: 26em;
      margin-left: 1em;
      margin-right: 1em;
    }
  }
  &-info {
    width: 15em;
    margin: 0 5px 0 5px;
    &-title {
      margin: 5px 0 0 0;
    }
    &-details {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      margin: 7px 0px 5px 0px;
      &-id {
        margin: 0px 0px 0px 0px;
        color: #5f646f;
      }
    }
    &-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &-right,
      &-left {
        display: flex;
        flex-direction: column;
        gap: 16px;
        &-latitude,
        &-longitude {
          display: flex;
          flex-direction: column;
          gap: 0px;
          height: 4.5em;
          &-title {
            color: #999a9d;
            font-weight: 700;
            margin-bottom: 0px;
            font-size: 14px;
            font-weight: 600;
          }
          &-value {
            font-size: 16px;
            font-weight: 600;
            margin-top: 5px;
          }
        }
        &-status {
          &-detail {
            color: #355d86;
            text-decoration: underline;
            font-weight: 700;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
