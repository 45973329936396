<template>
  <div :class="['kebab']">
    <button
      class="kebab-button"
      type="button"
      ref="menuRef"
      @click.stop="onMenuOpen"
      v-click-outside="onMenuClose"
    >
      ⋮
    </button>
    <Transition>
      <div
        v-show="showMenu"
        class="select select--container"
        ref="optionsList"
        :style="menuPosition"
      >
        <ul class="select select--list">
          <template v-for="(item, index) in Object.values(options)">
            <li
              :class="['select', 'select--list__option']"
              v-if="!item.hidden"
              :key="index"
            >
              <button
                class="button"
                type="button"
                :value="item.value"
                @click.stop="onMenuItemChange(item)"
              >
                <div class="list-item">{{ item.label }}</div>
              </button>
            </li>
          </template>
        </ul>
      </div>
    </Transition>
  </div>
</template>
<script>
import vClickOutside from "v-click-outside";

export default {
  data() {
    return {
      showMenu: false,
      menuPosition: {},
    };
  },
  props: {
    options: {
      type: Object,
    },
    payload: {
      type: Object,
    },
  },
  methods: {
    onMenuItemChange(item) {
      this.showMenu = false;
      this.$emit("menuItemChanged", item, this.payload);
    },
    onMenuClose() {
      if (this.$data.showMenu) {
        this.showMenu = false;
      }
    },
    onMenuOpen(event) {
      this.showMenu = !this.showMenu;
      const spacing = 12;
      this.$nextTick(() => {
        if (this.showMenu) {
          this.menuPosition = {};
          const button = event.currentTarget;
          this.menuPosition = {
            right: 0,
            top: `${button.clientHeight + spacing}px`,
          };
        }
      });
    },
  },
  directives: { clickOutside: vClickOutside.directive },
};
</script>
<style lang="scss">
.kebab {
  padding: 12px;
  align-items: center;
  width: auto;
  &-button {
    display: block;
    aspect-ratio: 1/1;
    min-width: unset;
    font-size: 24px;
    font-weight: bold;
    color: var(--txt-icon-secondary);
    background-color: transparent;
    &:hover {
      background-color: var(--clr-neutral-300);
    }
    transition: background-color 0.2s ease-in-out;
  }
}
</style>
