<template>
  <div
    style="
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 2;
    "
  >
    <div class="exportWaterFlow">
      <div class="exportWaterFlow-header">
        <p class="exportWaterFlow-header-title">Export Water Flow Data</p>
        <img
          class="exportWaterFlow-header-close"
          alt="x-icon"
          src="../../assets/x-icon.png"
          @click="closeForm"
        />
      </div>
      <p class="exportWaterFlow-header-subTitle">
        {{ detailWaterPoint?.name }}
      </p>
      <div class="exportWaterFlow-form">
        <select class="exportWaterFlow-form-select" v-model="monthlyTimeframe">
          <option value="default" disabled selected>
            Choose monthly timeframe...
          </option>
          <option value="3">3 months</option>
          <option value="6">6 months</option>
          <option value="9">9 months</option>
          <option value="12">12 months</option>
        </select>
        <select class="exportWaterFlow-form-select" v-model="interval">
          <option value="default" disabled selected>
            Choose Daily or Hourly...
          </option>
          <option value="Daily">Daily</option>
          <option value="Hourly">Hourly</option>
        </select>
        <!-- <select class="exportWaterFlow-form-select" v-model="typeFile">
          <option value="default" disabled selected>Select file type...</option>
          <option value="pdf">PDF</option>
          <option value="csv">CSV</option>
        </select> -->
        <button
          class="exportWaterFlow-form-button"
          :disabled="this.exportDisabled"
          @click="exportData()"
        >
          Export
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import exportFromJSON from "export-from-json";

export default {
  data() {
    return {
      monthlyTimeframe: "default",
      interval: "default",
      typeFile: "default",
    };
  },
  props: {
    sensorId: Number,
  },
  computed: {
    detailWaterPoint() {
      return this.$store.state.detailWaterPoint;
    },
    historicWaterFlow() {
      return this.$store.state.historicWaterFlow;
    },
    exportDisabled() {
      return this.monthlyTimeFrame == "default" || this.interval == "default";
    },
  },
  methods: {
    async exportData() {
      if (
        this.monthlyTimeframe !== "default" &&
        this.interval !== "default"
        // this.typeFile !== "default"
      ) {
        await this.$store.dispatch("exportWaterFlow", {
          id: this.$props.sensorId,
          monthlyTimeframe: this.monthlyTimeframe,
          interval: this.interval,
        });
        this.closeForm();
      }
    },
    closeForm() {
      this.$emit("close");
      this.save;
    },
  },
  watch: {
    historicWaterFlow(newValue) {
      const fileName = `${this.$props.sensorId}`;
      const exportType = exportFromJSON.types.csv;
      const data = newValue.data;
      exportFromJSON({ data, fileName, exportType });
    },
  },
};
</script>
<style scoped lang="scss">
.exportWaterFlow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 2;
  width: 25em;
  border: 2px solid #f1f3f9;
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 1em;
    padding: 0px 1em 0px 1em;
    padding-top: 1em;
    &-title {
      font-weight: 700;
    }
    &-subTitle {
      font-size: 14px;
      font-weight: 500;
      padding-left: 1.25em;
    }
    &-close {
      width: 0.7em;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1em;
    margin-left: 1em;
    margin-right: 1em;
    &-select {
      margin-top: 0.5em;
      padding: 0.3rem 0.8rem;
      border: 1px solid rgb(172, 172, 172);
      border-radius: 0.5rem;
      background-color: white;
    }
    &-button {
      align-self: flex-end;
      color: white;
      font-weight: 500;
      height: 2.25em;
      width: 5em;
      margin-top: 0.5em;
      margin-bottom: 1em;
      background-color: #003366;
      border: none;
      border-radius: 0.5em;
      &:hover {
        cursor: pointer;
        background-color: #002b55;
      }
      &:disabled {
        opacity: 0.5;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}
.exportWaterFlow-form-right-content-select {
  display: block;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.8em;
  width: 13em;
  margin-bottom: 1.25em;
  padding: 0.4em;
}
option:disabled {
  display: none;
}
</style>
