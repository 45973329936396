<template>
  <div class="chartsensors">
    <div class="chartsensors-cont">
      <div
        class="chartsensors-cont-num"
        v-for="(item, index) in chartData"
        :key="index"
      >
        <VMenu>
          <template #popper
            ><div class="TooltipPadding">
              <span class="PopperChartToolTipTop">{{
                `${item.value} ${statusTypeToMsg[item.status]} Sensors`
              }}</span>
              <br />
              <span class="PopperChartToolTipBottom">{{
                `This is ${getPercentage(
                  item.value,
                )}% of your total sensor count`
              }}</span>
            </div></template
          >
          <div
            class="chartsensors-cont-num-square"
            :class="`${item.status.toLowerCase().replace(/\s/g, '')}cc`"
          >
            &nbsp;
          </div>
        </VMenu>
        <p
          class="chartsensors-cont-num-title paragraph-large paragraph-large--heavy"
        >
          {{ item.value }}
        </p>
      </div>
    </div>
    <div class="chartsensors-graph-wrapper">
      <div class="chartsensors-graph">
        <div
          class="chartsensors-graph-elem"
          v-for="(item, index) in chartData"
          :key="index"
          :style="`width: ${item.width}%;`"
          :class="`${item.status.toLowerCase().replace(/\s/g, '')}`"
        >
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      statusTypes: ["Red", "Yellow", "Green", "Grey"],
      statusTypeToMsg: {
        Red: "Red",
        Yellow: "Yellow",
        Green: "Green",
        Grey: "Non-Active",
      },
    };
  },
  methods: {
    getPercentage(value) {
      if (this.totalWaterPoints) {
        const percentageValue = (value / this.totalWaterPoints) * 100;
        return Math.floor(percentageValue);
      } else {
        // default to zero if there's no data points
        return 0;
      }
    },
  },
  computed: {
    waterPoints() {
      return this.$store.state.filteredWaterPoints;
    },
    totalWaterPoints() {
      return this.waterPoints.length;
    },
    chartData() {
      const statusCountMap = {};

      // initialize status count map to fix the order of status
      this.statusTypes.forEach((status) => {
        statusCountMap[status] = 0;
      });
      this.waterPoints.forEach((point) => {
        statusCountMap[point.status]++;
      });
      const resultArray = Object.keys(statusCountMap).map((status) => ({
        value: statusCountMap[status],
        status: status,
        width: this.getPercentage(statusCountMap[status]),
      }));

      return resultArray;
    },
  },
};
</script>
<style scoped lang="scss">
.TooltipPadding {
  padding: 5px;
}
.PopperChartToolTipTop {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 600;
  overflow: none;
}
.PopperChartToolTipBottom {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #a5a5a5;
  overflow: none;
}
.chartsensors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1em;
  &-cont {
    display: flex;
    flex-direction: row;
    gap: 15px;
    &-num {
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: center;
      align-items: center;
      &-square {
        border-radius: 0.3em;
        width: 1em;
        height: 1em;
      }
      &-title {
        font-weight: 600;
      }
    }
  }

  &-graph {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-wrapper {
      border-radius: 0.2em;
      overflow: hidden;
    }
    &-elem {
      height: 0.35em;
      margin-right: 2px;
    }
  }
}
.chartsensors-cont-num-square.redcc,
.chartsensors-graph-elem.red {
  background-color: #e52e3c;
}
.chartsensors-graph-elem.red {
  width: calc(12 / (12 + 36 + 254 + 20) * 100%);
}
.chartsensors-cont-num-square.yellowcc,
.chartsensors-graph-elem.yellow {
  background-color: #ffd84c;
}
.chartsensors-graph-elem.yellow {
  width: calc(36 / (12 + 36 + 254 + 20) * 100%);
}
.chartsensors-cont-num-square.greencc,
.chartsensors-graph-elem.green {
  background-color: #04720d;
}
.chartsensors-graph-elem.green {
  width: calc(254 / (12 + 36 + 254 + 20) * 100%);
}
.chartsensors-cont-num-square.greycc,
.chartsensors-graph-elem.grey {
  background-color: #a5a5a5;
}
.chartsensors-graph-elem.grey {
  width: calc(20 / (12 + 36 + 254 + 20) * 100%);
}
.chartsensors-cont-num-square.greycc {
  margin-right: 0em;
  @media screen and (max-width: 767px) {
    margin-left: 1.5em;
  }
}
</style>
